import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { AnalysisEquipments } from '../pages/analysis/equipments';
import { AnalysisOrder } from '../pages/analysis/order';
import { CareRecordCSV } from '../pages/analysis/records';
import { UltrasonicData } from '../pages/analysis/ultrasonic-data';
import { LifeCSVExport } from '../pages/analysis/life';

export const AnalysisRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/equipments`}>
        <AnalysisEquipments />
      </Route>
      <Route path={`${path}/ultrasonic-data`}>
        <UltrasonicData />
      </Route>
      <Route path={`${path}/care-records`}>
        <CareRecordCSV />
      </Route>
      <Route path={`${path}/life`}>
        <LifeCSVExport />
      </Route>
      <Redirect path='*' to='/404' />
    </Switch>
  );
};
