import React from "react";
import { css } from "@emotion/react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { selfDataSelector } from '../../../redux/config/selfData';
import { useFetchTableData } from '../../../hooks/multi/useFetchTableData';
import { MenuItemProps, Select } from '../../../components/mui/select';
import { PanelContainer } from '../../../components/panel';
import { Button } from '../../../components/mui/button';
import { StyledTextField } from '../../../components/mui/textfield';
import { FacilityRadioGroup } from '../../../components/mui/radio';
import { OrderTitle } from './OrderTitle';
import { OrderTable } from './OrderTable';
import { styled } from '@mui/material';
import { color } from '../../../styles';
import { Diaper } from '../../../types/api/order/getOrders';
import api from '../../../api';
import { useSnackbar } from '../../../contexts/SnackbarContext';
import { navSlice } from '../../../redux/config/leftnav';
import { Loading } from '../../../components/mui/Loading';

interface Props {
  url: string;
}

type Facilitytype = 'nursingHome' | 'unit';

const TitleText = css`
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: bold;
`;

const ItemContainer = css`
  width: 100%;
  margin-bottom: 16px;
`;

const SelectRadioContainer = css`
  display: flex;
  justify-content: space-between;
`;

const SelectContainer = css`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 8px;
`;

const ButtonContainer = css`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const StyledButton = styled(Button)({
  border: `1px solid ${color.dark_gray}`,
  borderRadius: '100px',
  color: `${color.dark_gray}`,
  '&:hover': {
    borderColor: `${color.dark_gray}`,
    backgroundColor: `${color.button_gray}`,
  },
});

const NotFound = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
`;

const Warning = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${color.notification_red};
  margin-bottom: 16px;
`;

const CancelButton = styled(Button)({
  backgroundColor: color.button_gray,
  color: color.black,
  '&:hover': {
    backgroundColor: color.button_gray,
  },
  marginRight: '16px',
});

const ConfirmButton = styled(Button)({
  color: '#fff',
  '&:hover': {
    backgroundColor: color.green,
  },
});

const ConfirmTitle = css`
  margin-bottom: 24px;
`;

export const Order: React.FC<Props> = ({ url }) => {
  const { nursingHomeId } = useParams<{ nursingHomeId: string }>();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const savedOrderId = query.get('id');
  const { showSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const { selfUnits, selfUser, selfUnit } = useSelector(selfDataSelector);
  const [facilityType, setFacilityType] = React.useState<Facilitytype>('nursingHome');
  const [selectUnit, setSelectUnit] = React.useState<{ id: number | undefined; name: string | undefined } | null>(null);
  const [orderMemo, setOrderMemo] = React.useState('');
  const [isConfirm, setConfirm] = React.useState(false);
  const [onSend, setOnSend] = React.useState<boolean>(false);
  const orderableDiapers = useFetchTableData(+nursingHomeId, facilityType, selectUnit, savedOrderId);
  const [tableDiaperData, setTableDiaperData] = React.useState<{
    inner: Diaper[] | null;
    outer: Diaper[] | null;
    expendable: Diaper[] | null;
  }>({ inner: null, outer: null, expendable: null });

  const units = React.useMemo(() => {
    if (!selfUnits) return [];
    return selfUnits.map((unit) => {
      return { key: unit.id, value: unit.name };
    });
  }, [selfUnits]);

  const handleChangeAmount = React.useCallback(
    (diaper: Diaper, amount: number, type: 'inner' | 'outer' | 'expendable') => {
      if (type === 'expendable') {
        // 消耗品はマスタから引っ張るのでidはなく,diaperIdで検索する
        setTableDiaperData((prev) => {
          if (!prev.expendable) return { ...prev };
          const selectedIndex = prev.expendable.findIndex((v) => v.diaperId === diaper.diaperId);
          if (selectedIndex === -1) {
            return { ...prev };
          }
          prev.expendable[selectedIndex].amount = amount;
          return { ...prev };
        });
      } else {
        setTableDiaperData((prev) => {
          if (!prev.inner || !prev.outer) return { ...prev };
          const selectedIndex =
            type === 'inner'
              ? prev.inner.findIndex((v) => v.id === diaper.id)
              : prev.outer.findIndex((v) => v.id === diaper.id);
          if (selectedIndex === -1) {
            return { ...prev };
          }
          type === 'inner' ? (prev.inner[selectedIndex].amount = amount) : (prev.outer[selectedIndex].amount = amount);
          return { ...prev };
        });
      }
    },
    [tableDiaperData]
  );

  const handleGoStockSettings = React.useCallback(() => {
    history.push(`${url}/stocks`);
  }, []);

  const handleChangeOrderMemo = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOrderMemo(event.target.value);
    },
    [orderMemo]
  );

  const handleClickCancel = React.useCallback(() => {
    if (!isConfirm) {
      history.push(`${url}/saved-order`);
    } else {
      setConfirm(false);
    }
  }, [isConfirm]);

  const diaperSize =
    {
      "ss": "0",
      "s": "1",
      "m": "2",
      "l": "3",
      "ll": "4",
      "xl": "5",
      "unknown": "6"
    };

  const createOrderParams = React.useCallback(() => {
    if (!tableDiaperData.inner || !tableDiaperData.outer || !tableDiaperData.expendable) return;
    return [
      ...tableDiaperData.inner
        .filter((v) => v.amount)
        .map((v) => {
          return {
            diaperId: v.diaperId,
            nursingHomeDiaperId: v.id,
            amount: v.amount,
          };
        }),
      ...tableDiaperData.outer
        .filter((v) => v.amount)
        .map((v) => {
          return {
            diaperId: v.diaperId,
            nursingHomeDiaperId: v.id,
            amount: v.amount,
          };
        }),
      ...tableDiaperData.expendable
        .filter((v) => v.amount)
        .map((v) => {
          return {
            diaperId: v.diaperId,
            nursingHomeDiaperId: null,
            amount: v.amount,
          };
        }),
    ];
  }, [tableDiaperData]);

  const handleClickTemporarilySave = React.useCallback(async () => {
    setOnSend(true);
    const orders = createOrderParams();
    const params = {
      nursingHomeId,
      orders,
      orderStatus: 'temporarilySaved',
      quickOrder: 'normal',
      memo: orderMemo,
      nursingHomeUnitId: selfUser.hasUnitPerm
        ? selfUser.nursingHomeUnitId
        : facilityType === 'nursingHome'
        ? null
        : selectUnit?.id ?? null,
    };
    savedOrderId
      ? await api.put('/orders/:orderId', { orderId: savedOrderId, ...params })
      : await api.post('/nursing-homes/:nursingHomeId/orders', {
          ...params,
        });
    if (!savedOrderId) {
      dispatch(navSlice.actions.setTemporarilySavedBadge(1));
    }
    setOnSend(false);
    showSnackbar('一時保存されました');
    history.push(`${url}/saved-order`);
  }, [tableDiaperData, orderMemo, selfUser, savedOrderId]);

  const handleCreateOrder = React.useCallback(async () => {
    setOnSend(true);
    const orders = createOrderParams();
    savedOrderId
      ? await api.put('/orders/:orderId', {
          orderId: +savedOrderId,
          orders,
          orderStatus: 'recept',
          quickOrder: 'normal',
          memo: orderMemo,
          nursingHomeUnitId: selfUser.hasUnitPerm
            ? selfUser.nursingHomeUnitId
            : facilityType === 'nursingHome'
            ? null
            : selectUnit?.id ?? null,
        })
      : await api.post('/nursing-homes/:nursingHomeId/orders', {
          nursingHomeId,
          orders,
          orderStatus: 'recept',
          quickOrder: 'normal',
          memo: orderMemo,
          nursingHomeUnitId: selfUser.hasUnitPerm
            ? selfUser.nursingHomeUnitId
            : facilityType === 'nursingHome'
            ? null
            : selectUnit?.id ?? null,
        });
        setTimeout(() => {
          setOnSend(false);
          if (savedOrderId) {
            dispatch(navSlice.actions.setTemporarilySavedBadge(-1));
          }
          showSnackbar('発注が完了しました');
          history.push(`${url}/order-history`);
        }, 500);

  }, [tableDiaperData, orderMemo, selfUser, savedOrderId]);

  const handleClickConfirm = React.useCallback(() => {
    if (!isConfirm) {
      setConfirm(true);
    } else {
      handleCreateOrder();
    }
  }, [isConfirm]);

  const handleChangeFacility = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFacilityType(event.target.value as Facilitytype);
      if (!units.length) return;
      if (event.target.value === 'unit') {
        setSelectUnit({ id: units[0].key, name: units[0].value });
      } else {
        setSelectUnit({ id: undefined, name: undefined });
      }
    },
    [facilityType, units]
  );

  const handleChangeUnit = React.useCallback((e: MenuItemProps) => {
    setSelectUnit({ id: +e.key, name: e.value });
  }, []);

  const confirmButtonDisabled = React.useMemo(() => {
    return (
      !tableDiaperData.inner ||
      !tableDiaperData.outer ||
      !tableDiaperData.expendable ||
      (orderMemo && orderMemo.length >= 300) ||
      (tableDiaperData.inner.every((v) => !v.amount) &&
        tableDiaperData.outer.every((v) => !v.amount) &&
        tableDiaperData.expendable.every((v) => !v.amount))
    );
  }, [orderMemo, tableDiaperData]);

  React.useEffect(() => {
    if (!orderableDiapers.inner || !orderableDiapers.outer || !orderableDiapers.expendable) return;
    setTableDiaperData(orderableDiapers);

    setOrderMemo(orderableDiapers.memo);
  }, [orderableDiapers.inner, orderableDiapers.outer, orderableDiapers.expendable]);

  React.useEffect(() => {
    if (savedOrderId) {
      setSelectUnit({
        id: orderableDiapers.unitId ? +orderableDiapers.unitId : undefined,
        name: orderableDiapers.unitName ?? undefined,
      });
      setFacilityType(orderableDiapers.unitId ? 'unit' : 'nursingHome');
    } else if (selfUser.hasUnitPerm && selfUnit.id) {
      setSelectUnit({ id: selfUnit.id, name: selfUnit.name });
      setFacilityType('unit');
    } else {
      setSelectUnit({ id: undefined, name: '' });
      setFacilityType('nursingHome');
    }
  }, [selfUser, selfUnit, orderableDiapers.unitId, orderableDiapers.unitName]);

  return (
    <>
      <PanelContainer title={isConfirm ? '発注内容の確認' : '消耗品の発注'}>
        {!tableDiaperData.outer || !tableDiaperData.inner || !tableDiaperData.expendable ? (
          <Loading />
        ) : (
          <>
            <div css={ItemContainer}>
              {isConfirm && <div css={ConfirmTitle}>以下の発注内容で間違いないかご確認ください。</div>}
              <div css={TitleText}>発注元</div>
              {isConfirm ? (
                <>
                  発注ユニット:
                  {selfUser.hasUnitPerm ? (
                    <>{selfUnit.name}</>
                  ) : (
                    <>{facilityType === 'nursingHome' ? <>施設全体</> : <>{selectUnit?.name}</>}</>
                  )}
                </>
              ) : (
                <>
                  <div css={SelectContainer}>
                    <div css={ItemContainer}>
                      <div css={SelectRadioContainer}>
                        {(!!savedOrderId || selfUser.hasUnitPerm) && (
                          <>{selectUnit?.id ? <>{selectUnit?.name}</> : <>施設全体</>}</>
                        )}
                        {!savedOrderId && !selfUser.hasUnitPerm && (
                          <FacilityRadioGroup facilityType={facilityType} onChange={handleChangeFacility} />
                        )}
                        <StyledButton variant='outlined' startIcon={<ListAltIcon />} onClick={handleGoStockSettings}>
                          在庫管理・設定
                        </StyledButton>
                      </div>
                      {!savedOrderId && !selfUser.hasUnitPerm && facilityType === 'unit' && (
                        <Select
                          listData={units}
                          value={
                            selectUnit?.id && selectUnit.name && { key: String(selectUnit.id), value: selectUnit.name }
                          }
                          onChange={handleChangeUnit}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            {tableDiaperData.outer.length ? (
              <OrderTable
                title='テープ/パンツ'
                isConfirm={isConfirm}
                diapers={
                        tableDiaperData.outer
                        .sort((first, second) => diaperSize[first.diaperSize].localeCompare(diaperSize[second.diaperSize]))
                        .sort((first, second) => second.diaperFullName.localeCompare(first.diaperFullName))
                        .sort((first, second) => second.diaperSeries.localeCompare(first.diaperSeries))
                        }
                onAmountChange={handleChangeAmount}
              />
            ) : (
              <div css={NotFound}>発注可能なテープ/パンツがありません</div>
            )}
            {tableDiaperData.inner.length ? (
              <OrderTable
                title='パッド'
                isConfirm={isConfirm}
                diapers={tableDiaperData.inner}
                onAmountChange={handleChangeAmount}
              />
            ) : (
              <div css={NotFound}>発注可能なパッドがありません</div>
            )}

            {tableDiaperData.expendable.length ? (
              <OrderTable
                title='消耗品'
                isConfirm={isConfirm}
                isExpendable
                diapers={tableDiaperData.expendable}
                onAmountChange={handleChangeAmount}
              />
            ) : (
              <div css={NotFound}>発注可能な消耗品がありません</div>
            )}

            <div css={ItemContainer}>
              <OrderTitle title='発注メモ' />
              {!isConfirm ? (
                <StyledTextField
                  id='order-memo'
                  value={orderMemo}
                  multiline
                  style={{ width: '100%' }}
                  isError={orderMemo ? orderMemo.length >= 300 : false}
                  errorText={orderMemo && orderMemo.length >= 300 ? 'メモの文字数を300文字以下にしてください' : ''}
                  onChange={handleChangeOrderMemo}
                />
              ) : (
                /* eslint-disable-next-line no-extra-boolean-cast */
                <div>{!!orderMemo ? orderMemo : '発注メモはありません'}</div>
              )}
            </div>
            {!isConfirm && (
              <div css={ButtonContainer}>
                <Button
                  variant='outlined'
                  disabled={confirmButtonDisabled || onSend}
                  startIcon={
                    <img
                      src={confirmButtonDisabled ? '/images/icons/tmp-save-disable.svg' : '/images/icons/tmp-save.svg'}
                      alt='一時保存'
                    />
                  }
                  onClick={handleClickTemporarilySave}
                >
                  一時保存
                </Button>
              </div>
            )}

            <div css={ButtonContainer}>
              <CancelButton variant='contained' onClick={handleClickCancel}>
                {!isConfirm ? 'キャンセル' : '入力に戻る'}
              </CancelButton>
              <ConfirmButton
                variant='contained'
                disabled={confirmButtonDisabled || onSend}
                onClick={handleClickConfirm}
              >
                {!isConfirm ? '確認' : '発注'}
              </ConfirmButton>
            </div>
            <div css={Warning}>※10:30以降のご注文は、翌営業日の手配となります。</div>
          </>
        )}
      </PanelContainer>
    </>
  );
};
